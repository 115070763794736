import React, { useEffect, useRef, useState } from "react";
import AXIOS from "../../services/axios";
import "./createOrganization.css";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import { IoClose } from "react-icons/io5";
import { FaImage } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import OrganizationPayment from "../../components/organizationPayment/organizationPayment";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "../../services/redux/thunk/authThunk";
import { gstnValidation, pancardValidation } from "../../services/reusableCodes";

const OrganizationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    organizationName: "",
    registeredName: "",
    panCardNumber: "",
    panFile: "",
    panFileUrl: "",
    companyEmail: "",
    companyPhoneNumber: "",
    baseLocation: "",
    gst: "",
    gstFile: "",
    gstFileUrl: "",
    monthlyQuotationVolume: "",
    numberOfEmployees: "",
  });
  const gstFileInputRef = useRef(null);
  const panFileInputRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [isPayment, setIsPayment] = useState(true);
  const { pathname } = useLocation();
  const currentAccountType = useSelector(
    (state) => state.auth.currentAccountType
  );
  const organizationRole = useSelector((state) => state.auth.organizationRole);
  const isOrganizationForm = useSelector((state) => state.auth.isOrganizationForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if ( pathname === "/organization-form") {
      if (currentAccountType === "organization" && !isOrganizationForm && organizationRole === "owner") {
        setIsPayment(false);
      }else{
        navigate("/")
      }
    }
    
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
  
    // Required field validation
    if (!formData.organizationName)
      newErrors.organizationName = "Organization name is required";
    if (!formData.registeredName)
      newErrors.registeredName = "Registered name is required";
    if (!formData.panCardNumber)
      newErrors.panCardNumber = "PAN card number is required";
    else if (!pancardValidation(formData.panCardNumber))
      newErrors.panCardNumber = "Invalid PAN (e.g., ABCDE1234F)";

    if (!formData.panFile)
      newErrors.panFile = "PAN File is required";
  
    if (!formData.companyEmail)
      newErrors.companyEmail = "Company email is required";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.companyEmail))
      newErrors.companyEmail = "Invalid email";
  
    if (!formData.companyPhoneNumber)
      newErrors.companyPhoneNumber = "Company phone number is required";
    else if (!/^\d{10}$/.test(formData.companyPhoneNumber))
      newErrors.companyPhoneNumber = "Invalid phone (10 digits required)";
  
    if (!formData.baseLocation)
      newErrors.baseLocation = "Base location is required";
  

    if (formData.gst && !gstnValidation(formData.gst))
      newErrors.gst= "Invalid GST (e.g., 22ABCDE1234F1Z5)";
  
    if (!formData.monthlyQuotationVolume)
      newErrors.monthlyQuotationVolume = "Monthly quotation volume is required";
    if (!formData.numberOfEmployees)
      newErrors.numberOfEmployees = "Number of employees is required";
  
    return newErrors;
  };
  

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, fileName) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file, fileName);
  };

  const handleFileUpload = (file, fileName) => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      if (fileName === "gst") {
        setFormData({ ...formData, gstFile: file, gstFileUrl: fileUrl });
      }
      if (fileName === "pan") {
        setFormData({ ...formData, panFile: file, panFileUrl: fileUrl });
      }
    }
  };

  const handleDivClick = (fileName) => {
    fileName === "gst"
      ? gstFileInputRef.current.click()
      : panFileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const formDataToSend = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        if (!["panFile", "panFileUrl", "gstFile", "gstFileUrl"].includes(key)) {
          formDataToSend.append(key, value);
        }
      });

      if (formData.panFile) {
        const panFile = new FormData();
        panFile.append("file", formData.panFile);

        const fileResponse = await AXIOS.post(
          "/aws/upload-organization-pan-card",
          panFile,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      if (formData.gstFile) {
        const gstFile = new FormData();
        gstFile.append("file", formData.gstFile);

        const fileResponse = await AXIOS.post(
          "/aws/upload-organization-gst",
          gstFile,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const response = await AXIOS.post(
        "/organization/create-organization",
        formDataToSend
      );

      if (response.status === 200) {
        dispatch(refreshToken());
        navigate("/");
        setTimeout(() => window.location.reload(), 500);
      }
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };

  return (
    <div>
      {!isPayment && (
        <div className="organization-details-container">
          <img src={landingPageImg} alt="" className="cover-img" />
          <IoClose
            className="close-popup "
            onClick={() => setIsPayment(true)}
          />
          <div className="organization-details-section">
            <h2 className="organization-details-title">
              Organization Details Form
            </h2>
            <form className="organization-details-form" onSubmit={handleSubmit}>
              {/* Organization Name */}
              <div className="organization-details-group">
                <label className="organization-details-label">
                  Organization Name*
                </label>
                <input
                  type="text"
                  name="organizationName"
                  value={formData.organizationName}
                  onChange={handleChange}
                  className="organization-details-input"
                  placeholder="type here"
                />
                {errors.organizationName && (
                  <p className="error">{errors.organizationName}</p>
                )}
              </div>

              {/* Registered Name */}
              <div className="organization-details-group">
                <label className="organization-details-label">
                  Registered Name*
                </label>
                <input
                  type="text"
                  name="registeredName"
                  value={formData.registeredName}
                  onChange={handleChange}
                  className="organization-details-input"
                  placeholder="type here"
                />
                {errors.registeredName && (
                  <p className="error">{errors.registeredName}</p>
                )}
              </div>

              {/* Base Location */}
              <div className="organization-details-group">
                <label className="organization-details-label">
                  Base Location*
                </label>
                <input
                  type="text"
                  name="baseLocation"
                  value={formData.baseLocation}
                  onChange={handleChange}
                  className="organization-details-input"
                  placeholder="type here"
                />
                {errors.baseLocation && (
                  <p className="error">{errors.baseLocation}</p>
                )}
              </div>

              <div className="organization-details-group-container">
                {/* Company Email */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    Company Email*
                  </label>
                  <input
                    type="text"
                    name="companyEmail"
                    value={formData.companyEmail}
                    onChange={handleChange}
                    className="organization-details-input"
                    placeholder="type here"
                  />
                  {errors.companyEmail && (
                    <p className="error">{errors.companyEmail}</p>
                  )}
                </div>

                {/* Company Phone Number */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    Company Phone Number*
                  </label>
                  <input
                    type="tel"
                    name="companyPhoneNumber"
                    value={formData.companyPhoneNumber}
                    onChange={handleChange}
                    className="organization-details-input"
                    placeholder="type here"
                  />
                  {errors.companyPhoneNumber && (
                    <p className="error">{errors.companyPhoneNumber}</p>
                  )}
                </div>
              </div>

              <div className="organization-details-group-container">
                {/* PAN Card Number */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    PAN Card Number*
                  </label>
                  <input
                    type="text"
                    name="panCardNumber"
                    value={formData.panCardNumber.toUpperCase()}
                    onChange={handleChange}
                    className="organization-details-input"
                    placeholder="type here"
                  />
                  {errors.panCardNumber && (
                    <p className="error">{errors.panCardNumber}</p>
                  )}

                </div>

                {/* GST */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    GST (Optional)
                  </label>
                  <input
                    type="text"
                    name="gst"
                    value={formData.gst}
                    onChange={handleChange}
                    className="organization-details-input"
                    placeholder="type here"
                  />
                  {errors.gst && (
                    <p className="error">{errors.gst}</p>
                  )}
                </div>
              </div>

              <div className="organization-details-group-container">
                {/* PAN Card Number */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    Upload PAN*
                  </label>
                
                  {!formData.panFileUrl && (
                    <div
                      className="organization-file-upload-section"
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, "pan")}
                    >
                      <input
                        type="file"
                        ref={panFileInputRef}
                        style={{ display: "none" }}
                        accept=".jpeg, .jpg, .png"
                        onChange={(e) =>
                          handleFileUpload(e.target.files[0], "pan")
                        }
                      />
                      <div className="brand-logo-upload-section">
                        <div
                          className="brand-logo-upload"
                          onClick={() => handleDivClick("pan")}
                        >
                          <FaImage className="brand-logo-upload-icon" />
                        </div>
                        <p className="upload-instruction organization-file-upload-instruction">
                          Select or Drop your image
                        </p>
                        <p className="upload-support">
                          .jpeg, .jpg & .png files are only supported.
                        </p>
                      </div>
                    </div>
                  )}

{!formData.panFileUrl && errors.panFile && (
                    <p className="error">{errors.panFile}</p>
                  )}

                  {formData.panFileUrl && (
                    <div className="organization-file-upload-section">
                      <img
                        src={formData.panFileUrl}
                        alt="organization-uploaded-file"
                        className="organization-uploaded-file"
                      />
                      <MdDelete
                        className="organization-uploaded-delete-icon"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            panFile: "",
                            panFileUrl: "",
                          })
                        }
                      />
                    </div>
                  )}
                </div>

                {/* GST */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                   Upload GST (Optional)
                  </label>

                  {!formData.gstFile && (
                    <div
                      className="organization-file-upload-section"
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, "gst")}
                    >
                      <input
                        type="file"
                        ref={gstFileInputRef}
                        style={{ display: "none" }}
                        accept=".jpeg, .jpg, .png, .pdf"
                        onChange={(e) =>
                          handleFileUpload(e.target.files[0], "gst")
                        }
                      />
                      <div className="brand-logo-upload-section">
                        <div
                          className="brand-logo-upload"
                          onClick={() => handleDivClick("gst")}
                        >
                          <FaImage className="brand-logo-upload-icon" />
                        </div>
                        <p className="upload-instruction organization-file-upload-instruction">
                          Select or Drop your image
                        </p>
                        <p className="upload-support">
                          .jpeg, .jpg ,.png & .pdf files are only supported.
                        </p>
                      </div>
                    </div>
                  )}

{errors.panCardNumber && (
                    <p className="error" style={{opacity:0}}>{errors.panCardNumber}</p>
                  )}

                  {formData.gstFile && (
                    <div className="organization-file-upload-section">
                      <img
                        src={
                          formData.gstFile.type === "application/pdf" ||
                          String(formData.gstFile).endsWith(".pdf")
                            ? "https://cdn3.iconfinder.com/data/icons/muksis/128/pdf-512.png"
                            : formData.gstFileUrl
                        }
                        alt="organization-uploaded-file"
                        className="organization-uploaded-file"
                      />
                      <MdDelete
                        className="organization-uploaded-delete-icon"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            gstFile: "",
                            gstFileUrl: "",
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>


              <div className="organization-details-group-container">
                {/* Monthly Quotation Volume */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    Monthly Quotation Volume*
                  </label>
                  <input
                    type="text"
                    name="monthlyQuotationVolume"
                    value={formData.monthlyQuotationVolume}
                    onChange={handleChange}
                    className="organization-details-input"
                    placeholder="type here"
                  />
                  {errors.monthlyQuotationVolume && (
                    <p className="error">{errors.monthlyQuotationVolume}</p>
                  )}
                </div>

                {/* Number of Employees */}
                <div className="organization-details-group">
                  <label className="organization-details-label">
                    Number of Employees*
                  </label>
                  <input
                    type="text"
                    name="numberOfEmployees"
                    value={formData.numberOfEmployees}
                    onChange={handleChange}
                    className="organization-details-input"
                    placeholder="type here"
                  />
                  {errors.numberOfEmployees && (
                    <p className="error">{errors.numberOfEmployees}</p>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <button type="submit" className="organization-details-submit-btn">
                Proceed
              </button>
            </form>
          </div>
        </div>
      )}

      {isPayment && (
        <OrganizationPayment
          formData={formData}
          setIsPayment={setIsPayment}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default OrganizationForm;
