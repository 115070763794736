import React from 'react'
import "./enterpriseAlertPopup.css"
import { IoMdClose } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';

function EnterpriseAlertPopup() {
     const navigate = useNavigate();

  return (
    <div className="edit-project">
      <div className="re-assigning-project-container">
        <div className="new-project-content">
        <div className="membership-expiry-line" style={{background:'none'}}>
        Please share your organization details. Click to{" "}
              <span
                onClick={() => {
                  navigate("/organization-form");
                }}
              >
                Fill Form.
              </span>
             
            </div>
        </div>
      </div>
    </div>
  )
}

export default EnterpriseAlertPopup