import React, { useEffect, useRef, useState } from "react";
import "./myProfile.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import A1 from "../../assets/profile_avatars/A1.png";
import A2 from "../../assets/profile_avatars/A2.png";
import A3 from "../../assets/profile_avatars/A3.png";
import A4 from "../../assets/profile_avatars/A4.png";
import A5 from "../../assets/profile_avatars/A5.png";
import A6 from "../../assets/profile_avatars/A6.png";
import interioverse from "../../assets/logo.png";
import AXIOS from "../../services/axios";
import { useDispatch, useSelector } from "react-redux";
import { FaImage } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { refreshToken } from "../../services/redux/thunk/authThunk";
import { IoMenu } from "react-icons/io5";
import TeamMembers from "../../components/profileComponents/teamMembers/teamMembers";
import TeamProjects from "../../components/profileComponents/teamProjects/teamProjects";
import EnterpriseAlertPopup from "../../components/profileComponents/enterpriseAlertPopup/enterpriseAlertPopup";

function MyProfile() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone_number: "",
    address: "",
    pin_code: "",
  });
  const [brand, setBrand] = useState({
    brand_name: "",
    registered_name: "",
    tag_line: "",
    file: null,
    fileUrl: null,
  });
  const [profile, setProfile] = useState("");
  const [selectProfileImg, setSelectProfileImg] = useState("A1");
  const adminRole = useSelector((state) => state.auth.adminRole);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsOpenMenu] = useState(false);
  const currentAccountType = useSelector(
    (state) => state.auth.currentAccountType
  );
  const organizationRole = useSelector((state) => state.auth.organizationRole);
  const isOrganizationForm = useSelector(
    (state) => state.auth.isOrganizationForm
  );

  const profileImages = [A1, A2, A3, A4, A5, A6];

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setBrand({ file, fileUrl });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await AXIOS.get(
          `${
            currentAccountType === "organization"
              ? "/organization/organization-info"
              : "/user/my-profile"
          }`
        );
        if (response.status === 200) {
          const info =
            currentAccountType === "organization"
              ? response.data.organizationInfo
              : response.data.userInfo;
          setProfile(info);
          setSelectProfileImg(info.profile_picture);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [edit]);

  //for profile info
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" || name === "pin_code") {
      setForm((prev) => ({ ...prev, [name]: value.replace(/\D/g, "") }));
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  //for profile edit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedForm = {};
      for (const key in form) {
        if (form[key]) {
          updatedForm[key] = form[key];
        }
      }
      updatedForm["profile_picture"] = selectProfileImg;

      const response = await AXIOS.patch(
        `${
          currentAccountType === "organization"
            ? "/organization/update-organization"
            : "/user/update-user"
        }`,
        updatedForm
      );

      if (response.status === 200) {
        dispatch(refreshToken());
        setForm((prev) => ({
          ...prev,
          name: "",
          email: "",
          phone_number: "",
          address: "",
          pin_code: "",
        }));
        setEdit(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //for brand info
  const handleBrandChange = (e) => {
    const { name, value } = e.target;
    setBrand((prev) => ({ ...prev, [name]: value }));
  };

  const handleBrandSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedBrand = {};
      for (const key in brand) {
        if (brand[key]) {
          updatedBrand[key] = brand[key];
        }
      }

      const response = await AXIOS.patch("/user/update-user", updatedBrand);

      if (brand.file) {
        const formData = new FormData();
        formData.append("file", brand.file);

        const fileResponse = await AXIOS.post(
          "/aws/upload-brand-logo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      if (response.status === 200) {
        setBrand((prev) => ({
          ...prev,
          brand_name: "",
          registered_name: "",
          tag_line: "",
          file: null,
          fileUrl: null,
        }));
        setEdit(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrganizationBrandSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedBrand = {};

      for (const key in brand) {
        if (brand[key]) {
          // Rename registered_name to registeredName
          if (key === "registered_name") {
            updatedBrand["registeredName"] = brand[key];
          } else {
            updatedBrand[key] = brand[key];
          }
        }
      }

      const response = await AXIOS.patch(
        "/organization/update-organization",
        updatedBrand
      );

      if (brand.file) {
        const formData = new FormData();
        formData.append("file", brand.file);

        await AXIOS.post("/aws/upload-organization-brand-logo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.status === 200) {
        setBrand((prev) => ({
          ...prev,
          brand_name: "",
          registered_name: "",
          tag_line: "",
          file: null,
          fileUrl: null,
        }));
        setEdit(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBrandFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        setBrand((prev) => ({ ...prev, file: file, fileUrl: fileUrl }));
      }
    }
  };

  const removeImage = () => {
    setBrand({ file: null, fileUrl: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input field
    }
  };

  return (
    <article className="profile-container">
      {currentAccountType === "organization" &&
        !isOrganizationForm &&
        organizationRole === "owner" && <EnterpriseAlertPopup />}
      <section className="profile-navigation">
        <nav className="navigation-menu">
          <ul className="menu-list">
            <li
              className={`menu-item ${
                pathname === "/my-profile" ? "active-profile-menu" : ""
              }`}
              onClick={() => {
                setEdit(false);
                navigate("/my-profile");
                setForm({
                  name: "",
                  email: "",
                  phone_number: "",
                  address: "",
                  pin_code: "",
                });
              }}
            >
              My Profile
            </li>
            <li
              className={`menu-item ${
                pathname === "/my-brand" ? "active-profile-menu" : ""
              }`}
              onClick={() => {
                setEdit(false);
                navigate("/my-brand");
                setBrand({
                  brand_name: "",
                  registered_name: "",
                  tag_line: "",
                  file: null,
                  fileUrl: null,
                });
              }}
            >
              My Brand
            </li>
            {/* {adminRole === "Designer" && (
              <li
                className={`menu-item ${
                  pathname === "/my-orders" ? "active-profile-menu" : ""
                }`}
                onClick={() => {
                  setEdit(false);
                  navigate("/my-orders");
                }}
              >
                My Orders
              </li>
            )} */}
            {currentAccountType === "organization" &&
              organizationRole === "owner" && (
                <li
                  className={`menu-item ${
                    pathname === "/team-members" ? "active-profile-menu" : ""
                  }`}
                  onClick={() => {
                    setEdit(false);
                    navigate("/team-members");
                  }}
                >
                  My Team Members
                </li>
              )}

            {currentAccountType === "organization" &&
              organizationRole === "owner" && (
                <li
                  className={`menu-item ${
                    pathname === "/team-projects" ? "active-profile-menu" : ""
                  }`}
                  onClick={() => {
                    setEdit(false);
                    navigate("/team-projects");
                  }}
                >
                  Team Projects
                </li>
              )}
          </ul>
        </nav>
      </section>

      <section className="mobile-profile-navigation">
        <IoMenu
          className="profile-navigation-menu-icon"
          onClick={() => setIsOpenMenu(!isMenuOpen)}
        />
        {isMenuOpen && (
          <nav className="navigation-menu">
            <ul className="menu-list">
              <li
                className={`menu-item ${
                  pathname === "/my-profile" ? "active-profile-menu" : ""
                }`}
                onClick={() => {
                  setEdit(false);
                  navigate("/my-profile");
                  setForm({
                    name: "",
                    email: "",
                    phone_number: "",
                    address: "",
                    pin_code: "",
                  });
                  setIsOpenMenu(false);
                }}
              >
                My Profile
              </li>
              <li
                className={`menu-item ${
                  pathname === "/my-brand" ? "active-profile-menu" : ""
                }`}
                onClick={() => {
                  setEdit(false);
                  navigate("/my-brand");
                  setBrand({
                    brand_name: "",
                    registered_name: "",
                    tag_line: "",
                    file: null,
                    fileUrl: null,
                  });
                  setIsOpenMenu(false);
                }}
              >
                My Brand
              </li>

              {/* {adminRole === "Designer" && (
                <li
                  className={`menu-item ${
                    pathname === "/my-orders" ? "active-profile-menu" : ""
                  }`}
                  onClick={() => {
                    setEdit(false);
                    navigate("/my-orders");
                    setIsOpenMenu(false);
                  }}
                >
                  My Orders
                </li>
              )} */}

              {currentAccountType === "organization" &&
                organizationRole === "owner" && (
                  <li
                    className={`menu-item ${
                      pathname === "/team-members" ? "active-profile-menu" : ""
                    }`}
                    onClick={() => {
                      setEdit(false);
                      navigate("/team-members");
                    }}
                  >
                    My Team Members
                  </li>
                )}

              {currentAccountType === "organization" &&
                organizationRole === "owner" && (
                  <li
                    className={`menu-item ${
                      pathname === "/team-projects" ? "active-profile-menu" : ""
                    }`}
                    onClick={() => {
                      setEdit(false);
                      navigate("/team-projects");
                    }}
                  >
                    Team Projects
                  </li>
                )}
            </ul>
          </nav>
        )}
      </section>

      {/* my profile  */}
      {pathname === "/my-profile" && (
        <section className="profile-details">
          {!edit && (
            <div className="profile-header">
              <h1 className="profile-title">My Profile</h1>
              <div className="profile-info">
                <div className="profile-field">
                  <label htmlFor="name" className="profile-label">
                    Name
                  </label>
                  <p className="profile-value">{profile.name || 'Provide your organization name'}</p>
                </div>
                <div className="profile-field">
                  <label htmlFor="email" className="profile-label">
                    E-Mail
                  </label>
                  <p className="profile-value">{profile.email || 'Provide your organization email'}</p>
                </div>
                <div className="profile-field">
                  <label htmlFor="phone" className="profile-label">
                    Phone
                  </label>
                  <p className="profile-value">{profile.phone || 'Provide your organization Phone'}</p>
                </div>
                <div className="profile-field">
                  <label htmlFor="address" className="profile-label">
                    Address
                  </label>

                  <p className="profile-value borderless-profile-value">
                    {profile.address}
                  </p>
                </div>
                <div className="profile-field">
                  <label htmlFor="pincode" className="profile-label">
                    Pincode
                  </label>

                  <p className="profile-value borderless-profile-value">
                    {profile.pincode}
                  </p>
                </div>
              </div>

              <div className="profile-actions">
                <button
                  className="profile-button"
                  onClick={() => setEdit(true)}
                >
                  Edit Profile
                </button>
              </div>
            </div>
          )}

          {edit && (
            <form className="profile-header" onSubmit={handleSubmit}>
              <h1 className="profile-title">My Profile</h1>
              <div className="profile-info">
                <div className="profile-field">
                  <label htmlFor="name" className="profile-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="profile-value"
                    placeholder="your name"
                    onChange={handleChange}
                    value={profile.name}
                    name="name"
                    readOnly
                  />
                </div>
                <div className="profile-field">
                  <label htmlFor="email" className="profile-label">
                    E-Mail
                  </label>

                  <input
                    type="email"
                    className="profile-value"
                    placeholder="your email id"
                    onChange={handleChange}
                    value={profile.email}
                    name="email"
                    readOnly
                  />
                </div>
                <div className="profile-field">
                  <label htmlFor="phone" className="profile-label">
                    Phone
                  </label>

                  <input
                    type="text"
                    className="profile-value"
                    placeholder="your phone"
                    onChange={handleChange}
                    value={profile.phone}
                    name="phone_number"
                    maxLength={10}
                    readOnly
                  />
                </div>
                <div className="profile-field">
                  <label htmlFor="address" className="profile-label">
                    Address
                  </label>

                  <input
                    type="text"
                    className="profile-value borderless-profile-value"
                    placeholder="your address"
                    onChange={handleChange}
                    value={form.address}
                    name="address"
                  />
                </div>
                <div className="profile-field">
                  <label htmlFor="pincode" className="profile-label">
                    Pincode
                  </label>

                  <input
                    type="text"
                    className="profile-value borderless-profile-value"
                    placeholder="your pincode"
                    onChange={handleChange}
                    value={form.pin_code}
                    name="pin_code"
                    maxLength={6}
                  />
                </div>
              </div>

              <div className="profile-actions">
                <button className="profile-button" type="submit">
                  Save Profile
                </button>
              </div>
            </form>
          )}

          <div className="profile-id-container">
            <h2 className="profile-id">Profile ID : {profile.userId}</h2>

            <p className="profile-picture-label">Select Profile Picture</p>

            <div className="profile-picture">
              {profileImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="profile-images"
                  className={`profile-image ${
                    selectProfileImg === `A${index + 1}` ? "selected-img" : ""
                  }`}
                  onClick={() => edit && setSelectProfileImg(`A${index + 1}`)}
                  style={{ cursor: edit ? "pointer" : "default" }}
                />
              ))}
            </div>
          </div>
        </section>
      )}

      {/* my brand  */}
      {pathname === "/my-brand" && !edit && (
        <section className="profile-details">
          <div
            className={`profile-header ${
              pathname === "/my-brand" ? "brand-header" : ""
            }`}
          >
            <h1 className="profile-title">My Brand</h1>
            <div className="profile-info">
              <div className="profile-field">
                <label htmlFor="name" className="profile-label">
                  Brand Name
                </label>
                <p className="profile-value">
                  {profile.brand_name
                    ? profile.brand_name
                    : "Give your Brand Name"}
                </p>
              </div>
              <div className="profile-field">
                <label htmlFor="email" className="profile-label">
                  Registered Name
                </label>
                <p className="profile-value">
                  {profile.registered_name
                    ? profile.registered_name
                    : "Give your Registered Name"}
                </p>
              </div>
              <div className="profile-field">
                <label htmlFor="phone" className="profile-label">
                  Tagline
                </label>

                <p className="profile-value tagline-value">
                  {`"${
                    profile.tag_line
                      ? profile.tag_line
                      : "Give your Brand Tagline"
                  }"`}
                </p>
              </div>
            </div>

            <div className="profile-actions">
              {!edit && (
                <button
                  className="profile-button"
                  onClick={() => setEdit(true)}
                >
                  Edit Profile
                </button>
              )}
            </div>
          </div>
          <div className="profile-id-container brand-logo-container">
            <h2
              className={`profile-id ${
                pathname === "/my-brand" ? "brand-logo-text" : ""
              }`}
            >
              Brand Logo
            </h2>

            <div className="brand-logo">
              <img src={profile.brand_logo || interioverse} alt="brand-logo" />
            </div>
          </div>
        </section>
      )}

      {pathname === "/my-brand" && edit && (
        <section className="profile-details">
          <form
            className={`profile-header ${
              pathname === "/my-brand" ? "brand-header" : ""
            }`}
            onSubmit={
              currentAccountType === "organization"
                ? handleOrganizationBrandSubmit
                : handleBrandSubmit
            }
          >
            <h1 className="profile-title">My Brand</h1>
            <div className="profile-info">
              <div className="profile-field">
                <label htmlFor="name" className="profile-label">
                  Brand Name
                </label>
                <input
                  type="text"
                  className="profile-value"
                  placeholder="Brand name"
                  name="brand_name"
                  value={brand.brand_name}
                  onChange={handleBrandChange}
                />
              </div>
              <div className="profile-field">
                <label htmlFor="email" className="profile-label">
                  Registered Name
                </label>
                <input
                  type="text"
                  className="profile-value"
                  placeholder="Registered name"
                  name="registered_name"
                  value={brand.registered_name}
                  onChange={handleBrandChange}
                />
              </div>
              <div className="profile-field">
                <label htmlFor="phone" className="profile-label">
                  Tagline
                </label>
                <textarea
                  type="text"
                  className="profile-value tagline-text-area"
                  placeholder="Tagline"
                  name="tag_line"
                  value={brand.tag_line}
                  onChange={handleBrandChange}
                />
              </div>
            </div>

            <div className="profile-actions">
              <button className="profile-button" type="submit">
                Save Profile
              </button>
            </div>
          </form>
          <div className="profile-id-container brand-logo-container">
            <h2
              className={`profile-id ${
                pathname === "/my-brand" ? "brand-logo-text" : ""
              }`}
            >
              Brand Logo
            </h2>

            {!brand.file && (
              <div
                className="brand-logo"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".jpeg, .jpg, .png"
                  onChange={handleBrandFile}
                />
                <div className="brand-logo-upload-section">
                  <div className="brand-logo-upload" onClick={handleDivClick}>
                    <FaImage className="brand-logo-upload-icon" />
                  </div>
                  <p className="upload-instruction">
                    Select or Drop your <span>image (480px480p)</span> to
                    upload.
                  </p>
                  <p className="upload-support">
                    .jpeg, .jpg & .png files are only supported. Max. 1 image
                    can be uploaded at a time.
                  </p>
                </div>
              </div>
            )}

            {brand.fileUrl && (
              <div className="brand-logo">
                <img src={brand.fileUrl} alt="uploaded-logo" />
                <MdDelete
                  className="brand-logo-delete-icon"
                  onClick={removeImage}
                />
              </div>
            )}
          </div>
        </section>
      )}

      {/* my orders  */}
      {pathname === "/my-orders" && (
        <section className="profile-details order-details">
          <div className="order-history">
            <h1 className="order-history-title">Order History</h1>
            <div className="order-search-bar">
              <IoSearchOutline className="order-search-icon" />
              <input type="text" placeholder="Search in order history" />
            </div>
            {/* <button className="order-apply-button">Apply</button> */}
          </div>
          <div className="order-table">
            <table>
              <thead>
                <tr>
                  <th className="order-table-header">Plan</th>
                  <th className="order-table-header">Price</th>
                  <th className="order-table-header">Period</th>
                  <th className="order-table-header">Days Remaining</th>
                  <th className="order-table-header">Date</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 15 }).map((_, index) => (
                  <tr className="order-row" key={index}>
                    <td className="order-data">02-04</td>
                    <td className="order-data">₹490.00</td>
                    <td className="order-data">3 months</td>
                    <td className="order-data">
                      <FaRegCircleCheck className="status-icon" /> Active
                    </td>
                    <td className="order-data">22 Feb, 2024</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      )}

      {pathname === "/team-members" &&
        currentAccountType === "organization" &&
        organizationRole === "owner" && <TeamMembers />}
      {pathname === "/team-projects" &&
        currentAccountType === "organization" &&
        organizationRole === "owner" && <TeamProjects />}
    </article>
  );
}

export default MyProfile;
